<template>
  <bottom-view></bottom-view>
  <header-view></header-view>

  
  <div class="container data">


  
   <div  class="flex site-header-inner">

    
         

  <div>
      <img class="img-fix" src="front-end/images/ase-mobile.jpeg"/>
  </div>





<div class="flex site-header-inner">

  </div>
  

</div>
</div>




 

</template>

<script>
// @ is an alias to /src

import BottomView from '@/components/sections/menus/BottomView.vue';
import HeaderView from '@/components/sections/headers/HeaderView.vue';




export default {
  name: 'HomeView',
  components: {

    BottomView,
    HeaderView
  },
 
}
</script>