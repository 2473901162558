<template>
    <header class="site-header-app">
          <div class="container">
              <div class="site-header-inner">
                  <div class="brand header-brand">
                      <h1 class="m-0">
                          <router-link to="/explores">
                              <img class="header-logo-image" src="/front-end/images/logo.png" width="120" alt="Logo">
                          </router-link>
                      </h1>
                  </div>
              </div>
          </div>
      </header>
  </template>
  
  <script>
  // @ is an alias to /src
  
  
  export default {
    name: 'HeaderView',
    components: {
  
    },
  }
  </script>
 
  