import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import Camera from "simple-vue-camera";
import router from './router'
import store from './store'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone,faUser,faDashboard,faCog,faThLarge,faMessage,faHome,faSearch,faPowerOff,faTasks,faPlus,faArrowRight,faArrowLeft,faEdit,faImage,faTrash,faQrcode,faMobile,faLocation,faCamera,faEnvelope,faInfoCircle,faNewspaper,faBriefcase,faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faPhone,faUser,faDashboard,faCog,faThLarge,faMessage,faHome,faSearch,faPowerOff,faTasks,faPlus,faArrowRight,faArrowLeft,faEdit,faImage,faTrash,faQrcode,faMobile,faLocation,faCamera,faEnvelope,faInfoCircle,faNewspaper,faBriefcase,faUserGroup);



createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(Camera).use(router).mount('#app')
