<template>

<div class="menu-bottom-pwa">
  
  
  <div>
    <router-link to="/" >  
         <font-awesome-icon icon="home" :class="{'active-menu-pwa':currentRouteName=='home'}" />
         </router-link>
         
     </div>
     
     <div>
    <router-link to="/infos" >  
         <font-awesome-icon icon="info" :class="{'active-menu-pwa':currentRouteName=='infos'}" />
         </router-link>
         
     </div>
     <div>
          <router-link to="/profile">
          <font-awesome-icon icon="user"  :class="{'active-menu-pwa':currentRouteName=='profile'}" />
          </router-link>
      </div>
    


</div>

</template>
      
<script>
// @ is an alias to /src


export default {
  name: 'BottomView',
  components: {

  },
  computed:{
    currentRouteName() {
          return this.$route.name;
      }
      
  }
}
</script>
     
      