
import store from '@/store/index'

export default function auth({  next }) {
    // const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
  
    if (store.getters.isLoggedIn) {

      next({name:'home'});
       
      } 
       else {
        next();
      }
  }
