import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import auth from '@/middleware/auth'
import login from '@/middleware/login'
import middlewarePipeline from '@/middleware/middleware-pipeline'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },






//Start Token Route  

  {
    path: '/infos',
    name: 'infos',
    component: () => import( '../views/infos/IndexView.vue')
  },
  {
    path: '/infos/:token',
    name: 'single-info',
    component: () => import( '../views/infos/SingleView.vue')
  },

  {
    path: '/camera',
    name: 'camera',
    component: () => import( '../views/users/CameraView.vue')
  },

//End Token Route 






//Start Chat Route 

  {
    path: '/messages',
    name: 'messages',
    meta: { middleware: [auth] },
    component: () => import( '../views/users/messages/IndexView.vue')
  },
    
  {
    path: '/user/messages/new',
    name: 'new-message',
    meta: { middleware: [auth] },
    component: () => import('../views/users/messages/NewView.vue')
  },
  {
    path: '/messages/:id',
    name: 'single-message',
    props:true,
    component: () => import( '../views/users/messages/SingleView.vue')
  },
 

//End Chat Route   
 








//Start Profile Route 
 

  {
    path: '/profile',
    name: 'profile',
    component: () => import( '../views/users/ProfileView.vue')
  },
  {
    path: '/profile/edit',
    name: 'edit',
    meta: { middleware: [auth] },
    component: () => import( '../views/users/EditView.vue')
  },
  {
    path: '/profile/password',
    name: 'password',
    meta: { middleware: [auth] },
    component: () => import( '../views/users/PasswordView.vue')
  },

  {
    path: '/profile/device',
    name: 'device',
    meta: { middleware: [auth] },
    component: () => import( '../views/users/DeviceView.vue')
  },
  {
    path: '/profile/avator',
    name: 'avator',
    meta: { middleware: [auth] },
    component: () => import( '../views/users/AvatorView.vue')
  },

 //End Profile Route 
  










//Start Auth Route

  {
    path: '/login',
    name: 'login',
    meta: { middleware: [login] },
    component: () => import( '../views/auth/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( '../views/auth/RegisterView.vue')
  },
  {
    path: '/profile/logout',
    name: 'logout',
    meta: { middleware: [auth] },

    component: () => import('../views/users/LogOutView.vue')
  },

//End Auth Route









//Start Undefine Route

  {
    path: '/:pathMatch(.*)',
    name: 'Not Found',
    component: () => import( '../views/undefine/NotFound.vue')

  } 

//End Undefine Route


]

const router = createRouter({
  history: createWebHistory("/"),
  routes
})

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next };

 


  if (!middleware) {
    return next();
  }
 


  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router
