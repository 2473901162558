import { createStore } from 'vuex'

export default createStore({
  state: {
    Token: localStorage.getItem('repair') || null,
    Time: localStorage.getItem('date') || null,
    Id: localStorage.getItem('web_hook') || null,
  },
  getters: {
    isLoggedIn(state){
      return state.Token !== null; //double equals//7435547645
  },
  isLoggedOut(state){
    return state.Token === null; //double equals
},
  },
  mutations: {

  UNAUTHENTICATION(state){
      state.Token = null;
      state.Time = null;
      state.Id = null;
  },
  SETTOKEN(state,token){
    state.Token=token;
  },
  SETTIME(state,time){
    state.Time=time;
  },
  SETID(state,id){
    state.Id=id;
  }
  // AUTHENTICATION(state,token,time){
  //     state.Token = token;
  //     state.Time = time;
     
  //  }
  },
  actions: {
  },
  modules: {
  }
})
